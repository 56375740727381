import React from "react";
import { BsLinkedin, BsGithub } from "react-icons/bs";

const SocialMedia = () => {
  return <div className="app__social">
    <div>
      <a href="https://www.linkedin.com/in/el-mobachi-adil/" target="_blank" rel="noopener noreferrer">
        <BsLinkedin />
      </a>
    </div>
    <div>
      <a href="https://github.com/adilelmoba" target="_blank" rel="noopener noreferrer">
        <BsGithub />
      </a>
    </div>
  </div>;
};

export default SocialMedia;
