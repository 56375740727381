import React, { useEffect } from 'react';
import SanityBlockContent from '@sanity/block-content-to-react';

import { urlFor } from '../../client';
import './WorkPopup.scss';

function WorkPopup({ onClose, work }) {

  const serializers = {
    types: {
      image: ({ node }) => {
        const imageUrl = urlFor(node).url();  // Ensure the URL is a string
        return imageUrl ? <img src={imageUrl} alt="" /> : null;
      }
    }
  };

  useEffect(() => {
      const handleEscape = (e) => { if (e.key === "Escape") onClose(); };
      window.addEventListener("keydown", handleEscape);
      
      return () => window.removeEventListener("keydown", handleEscape);
  }, [onClose]);

  return (
    <div className="modal-backdrop" onClick={onClose}>
      <div className="modal-content scale-in-center" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>×</button>
        <h2>{work.title}</h2>
        { 
          work.longDescription && 
          <SanityBlockContent blocks={work.longDescription} serializers={serializers} />
        }
      </div>
    </div>
  );
}

export default WorkPopup;