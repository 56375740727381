import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'

import { AppWrapp, MotionWrap } from '../../wrapper'
import { images } from '../../constants'
import './Intro.scss'

const Intro = () => {

  return (
    <>
      <h2 className='head-text'>About Me</h2>

      <div className='app__intro-container app__flex'>

          <div className="app__intro-img">
            <img src={images.profile3} alt="profile 3" className='app__into-img--adil' />
            <img src={images.circle} alt="circle" className='app__into-img--circle' />
          </div>

          <div className="app__intro-text">
            A Full Stack Developer based in Cannes, France. I have a serious passion for building intuitive, dynamic user experiences and developing robust, scalable backend solutions.
          </div>

      </div>
    </>
  )
}

export default AppWrapp(
  MotionWrap(Intro, "app__intro"),
  'intro',
  "app__whitebg"
)