import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'

import { AppWrapp, MotionWrap } from '../../wrapper'
import { urlFor, client } from '../../client'
import './Skills.scss'

const Skills = () => {
  const [experiences, setExperiences] = useState([])
  const [skills, setSkills] = useState([])

  let previousYear = null

  useEffect(() => {
    const experiencesQuery = '*[_type == "experiences"]';
    const skillsQuery = '*[_type == "skills"]';

    client.fetch(experiencesQuery).then((data) => {
      const sortedExperiences = data.sort((a, b) => b.year - a.year)
      setExperiences(sortedExperiences)
    })

    client.fetch(skillsQuery).then((data) => {
      setSkills(data)
    })
  }, [])

  return (
    <>
      <h2 className='head-text'>Skills & Experience</h2>
    
      <div className="app__skills-container">
        <motion.div className='app__skills-list'>
          {skills?.map((skill, index) => (
            <motion.div
              whileInView={{ opacity: [0, 1] }}
              transition={{ duration: 0.5 }}
              className="app__skills-item app__flex"
              key={skill.name + index}
            >
              <div className='app__flex' style={{ backgroundColor: skill.bgColor }}>
                <img src={urlFor(skill.icon)} alt={skill.name} />
              </div>
              <p className="p-text">{skill.name}</p>
            </motion.div>
          ))}
        </motion.div>

        <div className='app__skills-exp'>
          {experiences.map((experience, index) => {
            let currentYear = experience.year
            let yearComponent = null
            if (currentYear !== previousYear) {
              yearComponent = <p className='bold-text'>{currentYear}</p>
            } else {
              yearComponent = (
                <p className='bold-text' style={{ visibility: 'hidden' }}>
                  {currentYear}
                </p>
              )
            }
            previousYear = currentYear
            return (
              <motion.div className="app__skills-exp-item" key={experience.year + index}>
                <div className='app__skills-exp-year'>{yearComponent}</div>

                <motion.div className='app__skills-exp-works'>
                  {experience.works.map((work, index) => (
                    <div key={work.name}>
                      <motion.div
                        whileInView={{ opacity: [0, 1] }}
                        transition={{ duration: 0.5 }}
                        className="app__skills-exp-work"
                        id={work.name}
                        key={work.name}
                      >
                        <h4 className='bold-text'>{work.name}</h4>
                        <p className='p-text'>{work.company}</p>
                      </motion.div>

                      <Tooltip
                        anchorId={work.name}
                        content={work.desc}
                        className="skills-tooltip"
                      />
                    </div>
                  ))}
                </motion.div>
              </motion.div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default AppWrapp(
  MotionWrap(Skills, 'app__skills'),
  'skills',
  'app__whitebg'
)